<template>
    <form autocomplete="off" class="">
        <div class="row g-4">
            <div class="col-lg-4">
                <vue-dropzone id="itemFrontPhotoDrop" ref="itemFrontPhotoDrop"
                :use-custom-slot="true" :maxFiles="1">
                   <div class="needsclick text-primary m-0">
                        <span class="bx bxs-camera-plus fs-1"></span>
                        <div> Add front photo </div>
                        <span class="fs-13">
                            (required)
                        </span>
                    </div>
                </vue-dropzone>
            </div>
             <div class="col-lg-4">
                <vue-dropzone id="itemBackPhotoDrop" ref="itemBackPhotoDrop"
                :use-custom-slot="true" :maxFiles="1">
                   <div class="needsclick text-primary m-0">
                        <span class="bx bxs-camera-plus fs-1"></span>
                        <div> Add back photo </div>
                        <span class="fs-13">
                            (required)
                        </span>
                    </div>
                </vue-dropzone>
            </div>
             <div class="col-lg-4">
                <vue-dropzone id="itemAboutPhotoDrop" ref="itemAboutPhotoDrop"
                :use-custom-slot="true" :maxFiles="1">
                   <div class="needsclick text-primary m-0">
                        <span class="bx bxs-camera-plus fs-1"></span>
                        <div> Add about photo </div>
                        <span class="fs-13">
                            (required)
                        </span>
                    </div>
                </vue-dropzone>
            </div>
            <div class="col-12">
                <vue-dropzone id="itemReceiptPhotoDrop" ref="itemReceiptPhotoDrop"
                :use-custom-slot="true" :maxFiles="1">
                    <div class="needsclick text-primary m-0">
                        <span class="bx bx-receipt fs-1"></span>
                        <div> Add receipt photo </div>
                    </div>
                </vue-dropzone>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Upload photos'}}</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import VueDropzone from "../VueDropzone.vue";

export default {
    components:{
        VueDropzone,
    },
    props:{
        itemId:{
            type: Number,
            required: true
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    methods:{
        create() {
            if(!this.collectFiles().length) return;
            const formData = new FormData()
            this.collectFiles().forEach((item) => formData.append(item.type, item.file))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/claims/${this.itemId}/attachments`, formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.resetForm(response.data.data)
                    }
                })
     
        },
        collectFiles(){
            let itemFiles = [
                {
                    type: "front_photo",
                    required: true,
                    ref: "itemFrontPhotoDrop"
                },{
                    type: "back_photo",
                    required: true,
                    ref: "itemBackPhotoDrop"
                },{
                    type: "about_photo",
                    required: true,
                    ref: "itemAboutPhotoDrop"
                },{
                    type: "receipt_photo",
                    required: false,
                    ref: "itemReceiptPhotoDrop"
                },
            ] 
            for(let i in itemFiles){
                if(itemFiles[i].required && !this.$refs[itemFiles[i].ref]){
                    this.alertError(`Please the ${itemFiles[i].type} is required`); return false
                }
                if(this.$refs[itemFiles[i].ref].getAcceptedFiles().length){
                    itemFiles[i].file = this.$refs[itemFiles[i].ref].getAcceptedFiles()[0];
                }
                if(itemFiles[i].required && !itemFiles[i].file ){
                    this.alertError(`Please the ${itemFiles[i].type} is required`); return false
                }
            }
            return itemFiles.filter((i => i.file))
        },
        collectFiles(){
            let itemFiles = [
                {
                    type: "front_photo",
                    required: true,
                    ref: "itemFrontPhotoDrop"
                },{
                    type: "back_photo",
                    required: true,
                    ref: "itemBackPhotoDrop"
                },{
                    type: "about_photo",
                    required: true,
                    ref: "itemAboutPhotoDrop"
                },{
                    type: "receipt_photo",
                    required: false,
                    ref: "itemReceiptPhotoDrop"
                },
            ] 
            for(let i in itemFiles){
                if(itemFiles[i].required && !this.$refs[itemFiles[i].ref]){
                    this.alertError(`Please the ${itemFiles[i].type} is required`); return false
                }
                if(this.$refs[itemFiles[i].ref].getAcceptedFiles().length){
                    itemFiles[i].file = this.$refs[itemFiles[i].ref].getAcceptedFiles()[0];
                }
                if(itemFiles[i].required && !itemFiles[i].file ){
                    this.alertError(`Please the ${itemFiles[i].type} is required`); return false
                }
            }
            return itemFiles.filter((i => i.file))
        },
        resetForm(item){
            this.$emit("closeMe", {...item} )
        }
    },
}
</script>

<style scoped>
.vue-dropzone{
    border-color: var(--bs-primary);
}
</style>