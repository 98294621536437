<template>
<div>
    <div class="row gy-3">
        <div class="col-12">
            <h5 class="mb-0">Claim Lookup</h5>
        </div>
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row gy-3 align-items-end">
                        <div class="col-lg-4">
                            <div class="">
                                <label class="form-label">Phone</label>
                                <input type="text" v-maska data-maska="(###) ###-####" v-model="data.mobile" class="form-control"
                                name="phone" placeholder="Phone" :class="{ 'is-invalid': v$.data.mobile.$error }">
                                
                                <div v-for="(item, index) in v$.data.mobile.$errors"
                                :key="index" class="invalid-feedback">
                                <span v-if="item.$message">{{ item.$message }}</span>
                                </div>      
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="">
                                <label class="form-label">Claim Id</label>
                                <input type="text" v-model="data.number" class="form-control"
                                name="number" placeholder="Claim Id" :class="{ 'is-invalid': v$.data.number.$error }">
                                <div v-for="(item, index) in v$.data.number.$errors"
                                :key="index" class="invalid-feedback">
                                <span v-if="item.$message">{{ item.$message }}</span>
                                </div>      
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <button @click.prevent="submitData()" class="btn mb-0 w-100 btn-primary"  type="button">
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="claim" class="row">
        <div class="col-12">
            <div v-if="claim.user" class="card bg-white overflow-hidden">
                <div class="card-body">
                    <div class="d-sm-flex align-items-center">
                        <div class="position-relative d-inline-block mb-4 mb-sm-0 me-3">
                            <img :src="absoluteUrl(claim.user.image)" class="rounded-circle avatar-xl img-thumbnail" />
                        </div>
                        <div>
                            <h5 class="fs-16 mb-1">
                                {{claim.user.first_name}} {{claim.user.last_name}}
                            </h5>
                            <p class="mb-0"> <span class="">{{claim.user.mobile}}</span></p>
                            <p class="mb-0"> <span class="">{{claim.user.email}}</span></p>
                        </div>
                    </div>
                </div>
                <div class="circles-container" ><div class="circle-back" ></div><div class="circle-front" ></div></div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="row g-2">
                        <div class="col-6"> 
                            <span>Claim ID</span>
                            <h6> {{claim.friendly_id}} </h6>
                        </div> 
                        <div class="col-6"> 
                            <span>Status</span>
                            <div><span v-html="statusBadge(claim.status)"> </span></div>
                        </div>
                        <div class="col-6"> 
                            <span>Loss type</span>
                            <h6> {{claim.loss_type.replace(/_/g, ' ')}} </h6>
                        </div>
                        <div class="col-sm-6"> 
                            <span>Loss date</span>
                            <h6> {{$filters.date_time(claim.loss_date, 1)}} </h6>
                        </div>
                        <div class="col-sm-6"> 
                            <span>Paypal email</span>
                            <h6> {{claim.paypal_email}} </h6>
                        </div>
                        <div class="col-sm-6"> 
                            <span>Digital signature</span>
                            <h6> {{ claim.digital_signature}} </h6>
                        </div>
                        <div class="col-12"> 
                            <span>Description</span>
                            <h6> {{ claim.description}} </h6>
                        </div>
                        <div class="col-12"> 
                            <div><strong>Item:</strong> {{ claim.item.title}} </div>
                        </div>
                    </div>
                    <div>
                        <div v-if="claim.status == 'draft'" class="col-12 mt-5">
                            <h5 class="mb-3">Add item photos</h5>
                            <add-claim-photo :itemId="claim.id" @closeMe="updateClaim($event)" />
                        </div>
                        <div v-else class="col-12 mt-5">
                            <h5 class="mb-3">Claim photos</h5>
                                <div class="row g-4">
                                    <div v-for="(file, fIndex) in claim.files" :key="fIndex"
                                    class="col-12 col-lg-3 col-sm-6" >
                                    <div>
                                        <div @click="showImg(fIndex)" class="cursor-pointer">
                                        <img :src="absoluteUrl(file.path)" class="mb-2 w-100" />
                                        </div>
                                        <span>{{file.description}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="mb-0">Claim service</h4>
                </div>
                <div class="card-body">
                    <div v-if="claim.status == 'pending'" class="bd-callout mb-3 mt-0 bd-callout-warning">
                        This is claim is pending approval. You can start work on this claim only when it has been approved. 
                    </div>

                    <div v-if="claim.status == 'completed'" class="bd-callout mb-3 mt-0 bd-callout-info">
                        This claim has been successfully processed and completed
                    </div>

                    <div v-if="claim.status == 'approved'">
                        <button @click.prevent="popupStartClaim = true" class="btn btn-primary" type="button"> Submit your amount </button>
                    </div>
                    <div v-if="claim.status == 'in_progress'">
                        <div class="bd-callout mb-3 mt-0 bd-callout-info">
                            Ensure to close this claim once your service has been provided.
                        </div>
                        <button @click.prevent="markCompleted()" class="btn btn-primary" type="button" >Mark completed </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="card">
        <div class="card-body text-center">
            <span>No claim to show</span>
        </div>
    </div>

    <b-modal v-model="popupStartClaim" hide-footer centered
      title="Add service charge" :no-close-on-backdrop="true"
      title-class="fs-6" @hide="resetData()">
      <start-claim-form v-if="popupStartClaim"
       @closeMe="resetData()" :item="claim" @updated="updateClaim($event)" />
    </b-modal>
    <vue-easy-lightbox v-if="claim" :visible="lightbox.visible" :imgs="lightboxFiles"
        :index="lightbox.index" @hide="initializeAll()"></vue-easy-lightbox>
</div>
</template>

<script>
import { required, minLength, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import AddClaimPhoto from '@/components/forms/AddClaimPhoto.vue';
import VueEasyLightbox from "vue-easy-lightbox";
import StartClaimForm from '@/components/forms/StartClaimForm.vue';

export default {
    name: 'claim-lookup',
    components: {
        AddClaimPhoto,
        VueEasyLightbox, StartClaimForm
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data(){
        return {
            data:{
                number: null,
                mobile: ""
            },
            claim: null,
            lightbox:{
                index: null,
                visible:false,
            },
            popupStartClaim: false
        }
    },
    // watch:
    validations: {
        data:{
            mobile: {
                required: helpers.withMessage("Phone is required", required),
            },
            number: {
                required: helpers.withMessage("Claim ID is required", required),
            },
        }
    },
    computed:{
        activeUser(){
            return this.$store.state.AppActiveUser
        },
        lightboxFiles(){
            return this.claim.files.map(file => {
                if(file.is_video){
                    return {
                        title: file.description,
                        thumb: this.absoluteUrl(file.path),
                        mediaType: 'video',
                        src: this.absoluteUrl(file.video_path)
                    }
                }else{
                    return {
                        title: file.description,
                        mediaType: 'image',
                        src: this.absoluteUrl(file.path) 
                    }
                }
            })
        },
  
    },
    methods:{
        updateClaim(data){
            Object.assign(this.claim, data)
            this.initializeAll();
        },
        submitData(){
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                this.claim = null
                this.data.mobile = this.$filters.unmaskMobile(this.data.mobile)
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.get(`/claims/lookup?${new URLSearchParams(this.data).toString()}`)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue")
                    if(response.data.success){
                        this.claim = response.data.data
                    }
                })
            });        
        },
        markCompleted(){
            Swal.fire({
                title: "Are you sure?",
                text: `You are about to mark this claim completed!`,
                reverseButtons:true,
                showCancelButton: true,
                cancelButtonColor: "#111",
                confirmButtonText: "Proceed!",
            }).then((result) => {
                if (result.value) {
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/claims/${this.claim.id}/complete`)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue")
                    if(response.data.success){
                        this.updateClaim(response.data.data)
                    }
                })
                }
            });
        },
        showImg(index) {
            this.lightbox = { index: index,visible: true};
        },
        initializeAll(){
            this.lightbox = { index: null, visible:false},
            this.popupStartClaim = false
        },
        checkRouter(){
            if(!this.$route.query.claim_id){ return; }
            this.data.number = this.$route.query.claim_id,
            this.data.mobile = this.activeUser.mobile
            this.submitData()
        }
    },
    mounted(){
       this.checkRouter()
    }
}
</script>

<style scoped>

</style>
