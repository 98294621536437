<template>
    <form autocomplete="off" class="">
        <div class="row g-4">
            <div class="col-12">
                <label for="inputEmail">
                    Service charge
                </label>
                <div class="input-group">
                    <span class="input-group-text">$</span>
                        <input v-model="data.service_charge" id="service_charge" class="form-control" type="number"
                        placeholder="Amount" :class="{ 'is-invalid': v$.data.service_charge.$error }" >
                        <span class="input-group-text">.00</span>
                    </div>
                    <div v-for="(item, index) in v$.data.service_charge.$errors"
                        :key="index" class="text-danger">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div> 
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="submit()"
                     type="button">Submit</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers, email, requiredIf } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props:{
        item:{
            type: Object,
            default: null
        },
    },
    data(){
        return{
            data: {
                service_charge: ""
            },
        }
    },
    validations() {
        return{
            data:{
                service_charge: {required: required},
            }
        }
    },
    methods:{
        submit() {
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                this.$store.dispatch("changeLoaderValue", true)
                formData.append('data', JSON.stringify(this.data))
                this.$http.post(`/claims/${this.item.id}/start`, formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$emit("updated", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
}
</script>


<style scoped>

</style>